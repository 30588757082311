export const LOADING = "LOADING";
export const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
export const PERFORM_LOGOUT = "PERFORM_LOGOUT";

export const ACCOUNT_FORM_FIELD_CHANGE = "ACCOUNT_FORM_FIELD_CHANGE";
export const STATS_UPDATED = "STATS_UPDATED";
export const ADMIN_SET_ELO_SUCCESS = "ADMIN_SET_ELO_SUCCESS";

export const ACCOUNT_UPDATE_DURING = "ACCOUNT_UPDATE_DURING";
export const ACCOUNT_UPDATE_ALERT = "ACCOUNT_UPDATE_ALERT";
export const ACCOUNT_UPDATE_ALERT_SNOOZE = "ACCOUNT_UPDATE_ALERT_SNOOZE";
export const ACCOUNT_UPDATE_COMPLETE = "ACCOUNT_UPDATE_COMPLETE";
export const ACCOUNT_SUCCESS_ALERT_SNOOZE = "ACCOUNT_SUCCESS_ALERT_SNOOZE";

export const ADMIN_LOAD_USERS = "ADMIN_LOAD_USERS";

export const ADMIN_SET_TARGET_USER_CONTEXT = "ADMIN_SET_TARGET_USER_CONTEXT";
export const ADMIN_SET_ELO_DIALOG_STATE = "ADMIN_SET_ELO_DIALOG_STATE";
export const ADMIN_SET_PLAYER_VERIFIED_DIALOG_STATE =
  "ADMIN_SET_PLAYER_VERIFIED_DIALOG_STATE";

export const ADMIN_SET_PLAYER_AS_VERIFIED_SUCCESS =
  "ADMIN_SET_PLAYER_AS_VERIFIED_SUCCESS";

export const ADMIN_SET_PLAYER_REJECTED_DIALOG_STATE =
  "ADMIN_SET_PLAYER_REJECTED_DIALOG_STATE";

export const ADMIN_SET_PLAYER_AS_REJECTED_SUCCESS =
  "ADMIN_SET_PLAYER_AS_REJECTED_SUCCESS";

export const ADMIN_SET_PLAYER_NOT_ELIGIBLE_DIALOG_STATE =
  "ADMIN_SET_PLAYER_NOT_ELIGIBLE_DIALOG_STATE";
export const ADMIN_SET_PLAYER_AS_NOT_ELIGIBLE_SUCCESS =
  "ADMIN_SET_PLAYER_AS_NOT_ELIGIBLE_SUCCESS";

export const ADMIN_SET_PLAYER_BANNED_DIALOG_STATE =
  "ADMIN_SET_PLAYER_BANNED_DIALOG_STATE";

export const ADMIN_SET_PLAYER_BANNED_SUCCESS =
  "ADMIN_SET_PLAYER_BANNED_SUCCESS";
