import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";

export default function SetPlayerBannedDialog({
  openState,
  userContext,
  onClose,
  onSetPlayerBanned,
}) {
  if ("row" in userContext) {
    const [days, setDays] = useState(7);
    const [reason, setReason] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);

    return (
      <div>
        {"row" in userContext && (
          <Dialog
            open={openState}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Ban {userContext.row.nickname}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please decide on how many days to ban the selected user and the
                reason for the ban.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                style={{ width: 100 }}
                onChange={(e) => {
                  setDays(e.target.value);
                }}
                value={days}
                id="days"
                label="Banned Days"
                type="number"
                fullWidth
              />
              <Typography>Reason for ban:</Typography>
              <TextField
                autoFocus
                margin="dense"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                value={reason}
                id="reason"
                style={{ width: 140 }}
                label="Ban Reason"
                type="text"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Dismiss
              </Button>
              <Button
                disabled={btnDisabled}
                onClick={() => {
                  setBtnDisabled(true);
                  onSetPlayerBanned(days, reason, userContext.row.userId);
                  setTimeout(() => {
                    setBtnDisabled(false);
                  }, 5 * 1000);
                }}
                color="primary"
              >
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }

  return <></>;
}
