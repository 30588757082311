import { restoreCartFromCookieStorage } from "./utils/cart";

export default {
  catalog: {
    account: {},
    cart: restoreCartFromCookieStorage(),
    accountUpdateDuring: false,
    accountUpdateComplete: null,
    accountUpdateAlert: false,
    accountUpdateAlertText: "",
    adminUserContext: {},
    eloDialogState: false,
    banPlayerDialogState: false,
    verifyPlayerDialogState: false,
    rejectPlayerDialogState: false,
    notEligiblePlayerDialogState: false,
    users: [],
    user: null,
    performLogout: false,
    snackbar: null,
    toggleSnackbar: false,
  },
};
