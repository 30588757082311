import { cloneDeep } from "lodash";
import objectAssign from "object-assign";

import * as actions from "./../../types/actionTypes";
import {
  addToCart,
  removeItemFromCart,
  updateProductQuantity,
  resetCart,
} from "./../utils/cart";
import initialState from "./../initialState";

import * as cookieStorage from "./../../actions/common/cookieStorage";

const cStorage = cookieStorage.getCookieStorage();

const handlers = new Map();

handlers.set(actions.LOAD_USER_PROFILE, (state, action) => {
  return objectAssign({}, state, {
    user: action.user,
  });
});

handlers.set(
  actions.ADMIN_SET_PLAYER_VERIFIED_DIALOG_STATE,
  (state, action) => {
    return objectAssign({}, state, {
      verifyPlayerDialogState: action.state,
    });
  }
);

handlers.set(
  actions.ADMIN_SET_PLAYER_REJECTED_DIALOG_STATE,
  (state, action) => {
    return objectAssign({}, state, {
      rejectPlayerDialogState: action.state,
    });
  }
);

handlers.set(actions.ADMIN_SET_PLAYER_BANNED_DIALOG_STATE, (state, action) => {
  return objectAssign({}, state, {
    banPlayerDialogState: action.state,
  });
});

handlers.set(actions.ADMIN_SET_PLAYER_BANNED_SUCCESS, (state) => {
  return objectAssign({}, state, {
    banPlayerDialogState: false,
  });
});

handlers.set(actions.ADMIN_SET_PLAYER_AS_REJECTED_SUCCESS, (state, action) => {
  return objectAssign({}, state, {
    rejectPlayerDialogState: false,
  });
});

handlers.set(actions.ADMIN_SET_PLAYER_AS_VERIFIED_SUCCESS, (state, action) => {
  return objectAssign({}, state, {
    verifyPlayerDialogState: false,
  });
});

handlers.set(actions.ADMIN_SET_ELO_SUCCESS, (state, action) => {
  return objectAssign({}, state, {
    eloDialogState: false,
  });
});

handlers.set(actions.ADMIN_SET_PLAYER_AS_NOT_ELIGIBLE_SUCCESS, (state) => {
  return objectAssign({}, state, {
    notEligiblePlayerDialogState: false,
  });
});

handlers.set(
  actions.ADMIN_SET_PLAYER_NOT_ELIGIBLE_DIALOG_STATE,
  (state, action) => {
    return objectAssign({}, state, {
      notEligiblePlayerDialogState: action.state,
    });
  }
);

handlers.set(actions.ADMIN_SET_ELO_DIALOG_STATE, (state, action) => {
  return objectAssign({}, state, {
    eloDialogState: action.state,
  });
});

handlers.set(actions.ADMIN_SET_TARGET_USER_CONTEXT, (state, action) => {
  const { userId, row } = action;
  return objectAssign({}, state, {
    adminUserContext: {
      userId,
      row,
      ...(action.material !== undefined ? { material: action.material } : {}),
    },
  });
});

handlers.set(actions.ADMIN_LOAD_USERS, (state, action) => {
  return objectAssign({}, state, {
    users: action.users,
  });
});

handlers.set(actions.PERFORM_LOGOUT, (state, action) => {
  return objectAssign({}, state, {
    user: null,
    performLogout: true,
  });
});

handlers.set(actions.LOADING, (state, action) => {
  return objectAssign({}, state, {
    listPending: action.loading,
  });
});

handlers.set(actions.ACCOUNT_SUCCESS_ALERT_SNOOZE, (state) => {
  return objectAssign({}, state, {
    accountUpdateComplete: false,
  });
});

handlers.set(actions.ACCOUNT_UPDATE_ALERT, (state, action) => {
  return objectAssign({}, state, {
    accountUpdateComplete: false,
    accountUpdateAlert: true,
    accountUpdateAlertText: action.text,
  });
});

handlers.set(actions.ACCOUNT_UPDATE_ALERT_SNOOZE, (state) => {
  return objectAssign({}, state, {
    accountUpdateAlert: false,
    accountUpdateAlertText: "",
  });
});

handlers.set(actions.ACCOUNT_UPDATE_COMPLETE, (state, action) => {
  const newUser = objectAssign({}, state.user, state.account);
  return objectAssign({}, state, {
    account: {},
    user: newUser,
    accountUpdateAlert: false,
    accountUpdateAlertText: "",
    accountUpdateComplete: true,
    accountUpdateDuring: false,
    accountUpdateCompleteResult: action.response,
  });
});

handlers.set(actions.ACCOUNT_UPDATE_DURING, (state) => {
  return objectAssign({}, state, {
    accountUpdateDuring: true,
  });
});

handlers.set(actions.ACCOUNT_FORM_FIELD_CHANGE, (state, { name, value }) => {
  let aValue = value;
  if (name === "age") {
    aValue = value.replace(/[^0-9]/g, "");
    if (parseInt(aValue) > 90) {
      aValue = 90;
    }
  }

  const newAccount = objectAssign({}, state.account, {
    [name]: aValue,
  });

  return objectAssign({}, state, {
    account: newAccount,
  });
});

export default handlers;
