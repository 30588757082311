import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function SetPlayerNotEligibleDialog({
  openState,
  userContext,
  onClose,
  onSetPlayerNotEligible,
}) {
  if ("row" in userContext) {
    const [btnDisabled, setBtnDisabled] = useState(false);

    return (
      <div>
        {"row" in userContext && (
          <Dialog
            open={openState}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Set {userContext.row.nickname} as Not Eligible
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                By clicking save changes you will mark this player as Not
                Eligible <br />
                This means he has not passed the basic demands to play on the
                Ouro network.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Dismiss
              </Button>
              <Button
                disabled={btnDisabled}
                onClick={() => {
                  setBtnDisabled(true);
                  onSetPlayerNotEligible(userContext.row.userId);
                  setTimeout(() => {
                    setBtnDisabled(false);
                  }, 5 * 1000);
                }}
                color="primary"
              >
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }

  return <></>;
}
