import React from "react";
import { Switch, Route } from "react-router";
import App from "../containers/App";

import Home from "../containers/Home";
import UsersPage from "../containers/Users";

import PrivacyPolicyPage from "../containers/PrivacyPolicyPage";
import TermsOfUsePage from "../containers/TermsOfUsePage";
import AboutUsPage from "../containers/AboutUsPage";
import GDPRPage from "../containers/GdprPage";

export const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/users",
    exact: true,
    component: UsersPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/privacy",
    exact: true,
    component: PrivacyPolicyPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/about-us",
    exact: true,
    component: AboutUsPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/data-protection-policy-and-gdpr",
    exact: true,
    component: GDPRPage,
    loadData: () => () => Promise.resolve(),
  },
  {
    path: "/terms-of-use",
    exact: true,
    component: TermsOfUsePage,
    loadData: () => () => Promise.resolve(),
  },
  {
    component: Home,
  },
];

export default function Router() {
  return (
    <App>
      <Switch>
        {routes.map((route) => (
          <Route key={route.path || "notfound"} {...route} />
        ))}
      </Switch>
    </App>
  );
}
