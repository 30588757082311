import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Alert, AlertTitle } from "@material-ui/lab";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CircularProgress from "@material-ui/core/CircularProgress";

import UsersTable from "./UsersTable";
import SetELO from "./SetELOModal";
import SetPlayerVerifiedDialog from "./SetPlayerVerifiedDialog";
import SetPlayerRejectedDialog from "./SetPlayerRejectedDialog";
import SetPlayerNotEligibleDialog from "./SetPlayerNotEligibleDialog";
import SetPlayerBannedDialog from "./SetPlayerBannedDialog";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 0,

    marginLeft: "auto",
    marginRight: "auto",

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  homepageRoot: {
    minHeight: 560,
    marginTop: 8,
  },
  button: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formGroup: {
    display: "block",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  lobbyContainer: {
    display: "flex",
    width: "75%",
    margin: "0 auto",
  },
  avatar: {
    width: 82,
    height: 82,
    margin: "15px auto",
    position: "relative",
    right: 1,
  },
  lobbyPersonBox: {
    width: 180,
    height: 190,
    marginRight: 20,
    borderRadius: 10,
    border: "solid 1px #adadad",
  },
  inviteLinkBtn: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  queueButton: {
    margin: "0 auto",
    fontSize: "24pt",
    padding: "4px 16px",
  },
  queueButtonCont: {
    margin: "0 auto",
    position: "relative",
    width: 150,
  },
  queueTimerCont: {
    border: "solid 1px #b5b1b3",
    borderRadius: 8,
    fontFamily: "Roboto",
    fontWeight: 525,
    fontSize: 28,
    width: 94,
    margin: "20px auto",
    padding: 16,
    position: "relative",
  },
  matchmakingOptionsCont: {
    border: "solid 1px #b5b1b3",
    borderRadius: 8,
    margin: "35px auto",
    position: "relative",
    textAlign: "left",
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 10,
    minHeight: 200,
    width: 740,
  },
  matchmakingSectionTitle: {
    position: "absolute",
    top: -23,
    fontWeight: 525,
  },
  mmOptionRowCb: {
    height: 30,
  },
  minHoursPlayedField: {
    width: 100,
    fontSize: 11,
  },
  anim: {
    position: "absolute",
    top: 13,
    right: -35,
  },
  vspacer10: {
    height: 10,
  },
  headline: {
    textAlign: "left",
    marginLeft: 30,
    fontSize: "14pt",
    fontWeight: 525,
  },
  smallSubTitle: {
    fontSize: "12pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  regularLink: {
    color: "#666",
  },
  "@media (min-width: 1024px)": {
    homepageRoot: {
      width: 1400,
      margin: "0 auto",
    },
    formGroup: {
      display: "flex",
      justifyContent: "center",
    },
    lobbyFrame: {
      margin: "20px auto",
      textAlign: "center",
      fontSize: "14pt",
      position: "relative",
      paddingTop: 49,
      minHeight: 500,
      // width: 1200,
    },
    homeContainer: {
      display: "none",
    },
  },
});

function UsersPage(props) {
  const { classes, actions, catalog } = props;
  const {
    user,
    users,
    eloDialogState,
    adminUserContext,
    verifyPlayerDialogState,
    rejectPlayerDialogState,
    notEligiblePlayerDialogState,
    banPlayerDialogState,
  } = catalog;

  useEffect(() => {
    if (!user) {
      // console.log("cant run the function");
    } else {
      actions.getAllUsers({ userId: user.userId });
    }
  }, [user]);

  const openSetEloDialog = ({ userId, row }) => {
    actions.setAdminTargetUserContext({ userId, row });
    actions.setELODialogState(true);
  };

  const openSetAsVerifiedDialog = ({ userId, row }) => {
    actions.setAdminTargetUserContext({ userId, row });
    actions.setPlayerVerifiedDialogState(true);
  };

  const openSetAsRejectedPassportDialog = ({ userId, row }) => {
    actions.setAdminTargetUserContext({ userId, row, material: "passport" });
    actions.setPlayerRejectedDialogState(true);
  };

  const openSetAsRejectedSelfieVideoDialog = ({ userId, row }) => {
    actions.setAdminTargetUserContext({
      userId,
      row,
      material: "selfie_video",
    });
    actions.setPlayerRejectedDialogState(true);
  };

  const openSetAsNotEligibleDialog = ({ userId, row }) => {
    actions.setAdminTargetUserContext({ userId, row });
    actions.setPlayerNotEligibleDialogState(true);
  };

  const openSetAsBannedDialog = ({ userId, row }) => {
    actions.setAdminTargetUserContext({ userId, row });
    actions.setPlayerBannedDialogState(true);
  };

  return (
    <div className={classes.homepageRoot}>
      <SetELO
        openState={eloDialogState}
        userContext={adminUserContext}
        onClose={() => {
          actions.setELODialogState(false);
        }}
        onSetElo={(elo, userId) => {
          actions.updateUserELO({ elo, userId, user });
        }}
      />

      <SetPlayerBannedDialog
        openState={banPlayerDialogState}
        userContext={adminUserContext}
        onClose={() => {
          actions.setPlayerBannedDialogState(false);
        }}
        onSetPlayerBanned={(days, reason, userId) => {
          actions.updateUserAsBanned({ days, reason, userId, user });
        }}
      />

      <SetPlayerNotEligibleDialog
        openState={notEligiblePlayerDialogState}
        userContext={adminUserContext}
        onClose={() => {
          actions.setPlayerNotEligibleDialogState(false);
        }}
        onSetPlayerNotEligible={(userId) => {
          actions.updateSetUserAsNotEligible({ userId, user });
        }}
      />

      <SetPlayerVerifiedDialog
        openState={verifyPlayerDialogState}
        userContext={adminUserContext}
        onClose={() => {
          actions.setPlayerVerifiedDialogState(false);
        }}
        onSetPlayerVerified={(userId) => {
          actions.updateSetUserAsVerified({ userId, user });
        }}
      />

      <SetPlayerRejectedDialog
        openState={rejectPlayerDialogState}
        userContext={adminUserContext}
        onClose={() => {
          actions.setPlayerRejectedDialogState(false);
        }}
        onSetPlayerRejected={(userId, material) => {
          actions.updateSetUserAsRejected({ userId, user, material });
        }}
      />

      <Paper className={classes.lobbyFrame}>
        {!user && <Typography>Please wait...</Typography>}

        {user && (
          <>
            <Typography
              component="h1"
              variant="h1"
              className={classes.headline}
            >
              Users Management
            </Typography>

            <UsersTable
              openSetEloDialog={openSetEloDialog}
              openSetAsVerifiedDialog={openSetAsVerifiedDialog}
              openSetAsRejectedPassportDialog={openSetAsRejectedPassportDialog}
              openSetAsRejectedSelfieVideoDialog={
                openSetAsRejectedSelfieVideoDialog
              }
              openSetAsNotEligibleDialog={openSetAsNotEligibleDialog}
              openSetAsBannedDialog={openSetAsBannedDialog}
              rows={users}
              user={user}
            />
            <div className={classes.vspacer10} />
            <div className={classes.vspacer10} />
          </>
        )}
      </Paper>
    </div>
  );
}

UsersPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(UsersPage);
