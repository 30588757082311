import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function SetELODialog({
  openState,
  userContext,
  onClose,
  onSetElo,
}) {
  if ("row" in userContext) {
    const defaultEloFigure = userContext.row.elo || 1000;
    const [elo, setElo] = useState(defaultEloFigure);
    const [btnDisabled, setBtnDisabled] = useState(false);

    return (
      <div>
        {"row" in userContext && (
          <Dialog
            open={openState}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Set {userContext.row.nickname}'s ELO
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please set the numeric ELO value for the user{" "}
                <strong>{userContext.row.nickname}</strong> and click save
                changes to apply the change
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                onChange={(e) => {
                  setElo(e.target.value);
                }}
                value={elo}
                id="elo"
                label="ELO"
                type="number"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Dismiss
              </Button>
              <Button
                disabled={btnDisabled}
                onClick={() => {
                  setBtnDisabled(true);
                  onSetElo(elo, userContext.row.userId);
                  setTimeout(() => {
                    setBtnDisabled(false);
                  }, 5 * 1000);
                }}
                color="primary"
              >
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }

  return <></>;
}
