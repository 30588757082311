import React from "react";
import PropTypes from "prop-types";
import { Redirect, } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Container, Box, } from "@material-ui/core";

const styles = (theme) => ({
  homepageRoot: {
    minHeight: 560,
    marginTop: -16,
  },
  button: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  banner: {
    marginTop: 10,
    position: "relative",
    display: "block",
  },
  imageBackdrop: {
    display: "block",
    height: 196,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  sloganContainer: {
    justifyContent: "center",
    display: "flex",
    flexFlow: "column",
    position: "absolute",
    cursor: "default",
    top: 0,
    left: 0,
    height: 196,
  },
  homeContainer: {
    display: "block",
  },
  homeContainerDesktop: {
    display: "none",
  },
  pillarsContainer: {
    background: "#fff",
  },
  topSellerInnerContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  recentSizesContainer: {},
  formGroup: {
    display: "block",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  popularSizesCont: {
    margin: 8,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  videoContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  overlayVideo: {
    position: "relative",
    height: 196,
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
  },
  root: {
    minHeight: "100vh",
    display: "flex",
    paddingTop: 40,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      'url("https://r4.wallpaperflare.com/wallpaper/413/925/249/minimalism-abstract-pattern-digital-art-wallpaper-ff7c12af3dc9d5a8978d62a4e4c3f010.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    textAlign: "center",
  },
  heroText: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  pillar: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(4),
  },
  pillarsDesktop: {
    display: "none",
  },
  pillarsMobile: {
    display: "flex",
    width: "100%",
  },
  "@media (min-width: 1024px)": {
    recentSizesContainer: {
      display: "none",
    },
    desktopSubmitButton: {
      width: 240,
      margin: "12px auto",
    },
    formGroup: {
      display: "flex",
      justifyContent: "center",
    },
    pillarsMobile: {
      display: "none",
    },
    pillarsDesktop: {
      display: "block",
    },
    homeBoxTitle: {
      fontSize: 22,
      margin: 0,
      padding: 0,
    },
    sloganDesktop: {
      background: "#4f4f4f",
      padding: "0 8px",
      fontSize: "50px",
      textShadow: "4px 0px 7px rgba(42, 45, 51, 0.5)",
      fontWeight: "540",
      color: "#fefefe",
    },
    sloganSubtitleDesktop: {
      background: "#4f4f4f",
      padding: "0 8px",
      fontSize: "24px",
      fontWeight: "540",
      color: "#ffe89c",
    },
    sloganContainerDesktop: {
      width: 1000,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "right",
      position: "relative",
      top: 25,
      marginLeft: "auto",
      marginRight: "auto",
    },
    homeContainerDesktop: {
      display: "block",
      position: "relative",
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
    banner: {
      marginTop: 49,
      height: 760,
    },
  },
});

class HomePage extends React.Component {
  constructor() {
    super();
    this.state = {
      fireRedirect: false,
    };
  }

  validateForm = (e) => {
    e.preventDefault();
    this.setState({ fireRedirect: true });
  };

  render() {
    const { classes, catalog } = this.props;
    const { user } = catalog;

    if (!user) {
      // There is no user connected so just render the homepage
      return (
        <div className={classes.homepageRoot}>
          <div className={classes.root}>
            <Container maxWidth="md">
              <Box className={classes.heroText}>
                <Typography variant="h2" component="h1">
                  Elevate Your Game: Where Skill Meets Integrity
                </Typography>
                <Typography variant="h5" component="p" gutterBottom>
                  Join a community committed to clean, fair, and competitive
                  gameplay.
                </Typography>
                {/* <Button
                  variant="contained"
                  className={classes.button}
                  size="large"
                >
                  Get Started
                </Button> */}
              </Box>

              {/* Pillar 1 */}
              <Box className={classes.pillar}>
                <Typography variant="h4" component="h2">
                  Focused on Clean Gaming
                </Typography>
                <Typography variant="body1">
                  We prioritize the integrity of our community. Our stringent
                  KYC process ensures that only serious, verified players make
                  it into our matches, creating a fair and competitive
                  environment.
                </Typography>
              </Box>

              {/* Pillar 2 */}
              <Box className={classes.pillar}>
                <Typography variant="h4" component="h2">
                  Advanced Matchmaking Algorithms
                </Typography>
                <Typography variant="body1">
                  Our state-of-the-art matchmaking algorithms pair you with
                  players of similar skill and dedication. Say goodbye to
                  cheaters, smurfers, and time-wasters, and hello to quality
                  matches every time.
                </Typography>
              </Box>

              <Box className={classes.pillar}>
                <Typography variant="h4" component="h2">
                  Precision Matchmaking: The Future of Fair Play
                </Typography>
                <Typography variant="body1">
                  At Ouro Matchmaking, our advanced algorithm offers fresh,
                  innovative filters to tailor your gaming experience like never
                  before. This is just the beginning—our commitment is to bring
                  matchmaking to an unprecedented level of granularity. With a
                  relentless focus on continuous improvement, we're dedicated to
                  refining every match, with much more to come in the future!
                </Typography>
              </Box>

              {/* Pillar 3 */}
              <Box className={classes.pillar}>
                <Typography variant="h4" component="h2">
                  Carry Over Your ELO
                </Typography>
                <Typography variant="body1">
                  Compete with players of your caliber through our ELO-based
                  matchmaking system. Your hard-earned FaceIT ELO is respected
                  here.
                </Typography>
              </Box>
            </Container>
          </div>
        </div>
      );
    } else if (user && user.email && user.email.length > 0) {
      return <Redirect to={`/users`} />;
    }
  }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomePage);
