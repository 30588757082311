import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function SetPlayerRejectedDialog({
  openState,
  userContext,
  onClose,
  onSetPlayerRejected,
}) {
  if ("row" in userContext) {
    const [btnDisabled, setBtnDisabled] = useState(false);

    return (
      <div>
        {"row" in userContext && "material" in userContext && (
          <Dialog
            open={openState}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Set {userContext.row.nickname} as Rejected
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                By clicking save changes you will reject the player{" "}
                <strong>{userContext.row.nickname}</strong> uploaded{" "}
                {userContext.material.replaceAll("_", " ")}.
                <br />
                He will be notified so that he can upload a new evidence.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Dismiss
              </Button>
              <Button
                disabled={btnDisabled}
                onClick={() => {
                  setBtnDisabled(true);
                  onSetPlayerRejected(
                    userContext.row.userId,
                    userContext.material
                  );
                  setTimeout(() => {
                    setBtnDisabled(false);
                  }, 5 * 1000);
                }}
                color="primary"
              >
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }

  return <></>;
}
