import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";

import utils from "./../../universal/utils";

const basePath = utils.getEdgeBasePath();

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    width: 180,
    disablePadding: false,
    label: "Player Name",
  },
  {
    id: "nickname",
    width: 180,
    numeric: false,
    disablePadding: true,
    label: "Nickname",
  },
  {
    id: "status",
    width: 80,
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "elo",
    align: "center",
    width: 80,
    numeric: false,
    disablePadding: true,
    label: "ELO",
  },
  {
    id: "country",
    align: "center",
    width: 80,
    numeric: false,
    disablePadding: true,
    label: "Country",
  },
  {
    id: "age",
    align: "center",
    numeric: false,
    disablePadding: true,
    label: "Age",
  },
  {
    id: "steamId64",
    numeric: false,
    align: "center",
    disablePadding: true,
    label: "Steam64 ID",
  },
  {
    id: "steamProfileUrl",
    numeric: false,
    align: "center",
    disablePadding: true,
    label: "Steam Profile",
  },
  {
    id: "faceitProfileUrl",
    numeric: false,
    align: "center",
    disablePadding: true,
    label: "FaceIT Profile",
  },
  {
    id: "passport",
    numeric: false,
    align: "center",
    disablePadding: true,
    label: "Passport",
  },
  {
    id: "Selfie Video",
    numeric: false,
    align: "center",
    disablePadding: true,
    label: "Selfie Video",
  },
  {
    id: "actions",
    numeric: false,
    align: "center",
    disablePadding: true,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={"width" in headCell ? { width: headCell.width } : {}}
            align={headCell.align || "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              style={"align" in headCell ? { textAlign: headCell.align } : {}}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span
                style={
                  "align" in headCell && headCell.align === "center"
                    ? { position: "relative", left: 10 }
                    : {}
                }
              >
                {headCell.label}
              </span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          &nbsp;
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  steamIcon: {
    height: 48,
  },
  faceitIcon: {
    height: 32,
    width: 32,
    background: "#ffffff",
    padding: 5,
    borderRadius: 8,
    border: "solid 1px #666666",
  },
  passportButton: {
    color: "#666666",
    height: 24,
    width: 24,
  },
  rejected: {
    background: "#b31902",
    color: "#e6e6e6",
    paddingTop: 2,
  },
  pending: {
    background: "#999999",
    paddingTop: 2,
  },
  verified: {
    background: "#21520e",
    color: "#e6e6e6",
    paddingTop: 2,
  },
  noteligible: {
    background: "#4a0d4d",
    color: "#e6e6e6",
    paddingTop: 2,
  },
  banned: {
    background: "#000000",
    color: "#e6e6e6",
    paddingTop: 2,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  rows,
  user,
  openSetEloDialog,
  openSetAsVerifiedDialog,
  openSetAsRejectedPassportDialog,
  openSetAsRejectedSelfieVideoDialog,
  openSetAsNotEligibleDialog,
  openSetAsBannedDialog,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [activeUserContext, setActiveUserContext] = React.useState({});

  const handleToggle = (row) => {
    setActiveUserContext({ userId: row.userId, row });
    setOpen((prevOpen) => !prevOpen);
  };

  const adjustElo = ({ userId, row }, e) => {
    openSetEloDialog({ userId, row });
    handleClose(e);
  };

  const setAsVerified = ({ userId, row }, e) => {
    openSetAsVerifiedDialog({ userId, row });
    handleClose(e);
  };

  const setAsRejectedByPassport = ({ userId, row }, e) => {
    openSetAsRejectedPassportDialog({ userId, row });
    handleClose(e);
  };

  const setAsRejectedByVideo = ({ userId, row }, e) => {
    openSetAsRejectedSelfieVideoDialog({ userId, row });
    handleClose(e);
  };

  const setAsNotEligible = ({ userId, row }, e) => {
    openSetAsNotEligibleDialog({ userId, row });
    handleClose(e);
  };

  const setAsBanned = ({ userId, row }, e) => {
    openSetAsBannedDialog({ userId, row });
    handleClose(e);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  let displayAccountStatus = row.accountStatus.toUpperCase();
                  if (displayAccountStatus === "NOTELIGIBLE") {
                    displayAccountStatus = "NOT ELIGIBLE";
                  }

                  return (
                    <TableRow
                      hover
                      //   onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell> */}
                      <TableCell component="th" id={labelId} scope="row">
                        {row.name} <br />
                        {row.email}
                      </TableCell>
                      <TableCell padding="none">{row.nickname}</TableCell>
                      <TableCell padding="none" align="center">
                        <Chip
                          className={classes[row.accountStatus || "pending"]}
                          label={displayAccountStatus}
                        />
                      </TableCell>
                      <TableCell padding="none" align="center">
                        {row.elo || "1000"}
                      </TableCell>
                      <TableCell padding="none" align="center">
                        {row.country}
                      </TableCell>
                      <TableCell padding="none" align="center">
                        {row.age}
                      </TableCell>
                      <TableCell padding="none" align="center">
                        {row.steamId64}
                      </TableCell>
                      <TableCell align="center">
                        <a
                          className={classes.steamProfileLinkButton}
                          href={row.steamProfileUrl}
                          target="_blank"
                        >
                          <img
                            className={classes.steamIcon}
                            src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/steam-icon-14889.png"
                          />
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <a
                          className={classes.faceitProfileLinkButton}
                          href={row.faceitProfileUrl}
                          target="_blank"
                        >
                          <img
                            className={classes.faceitIcon}
                            src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/faceit-seeklogo.svg"
                          />
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <a
                          className={classes.passportButton}
                          target="_blank"
                          href={`${basePath}/api/v7/media/darkon/${user.userId}/${row.userId}`}
                        >
                          <AssignmentIndOutlinedIcon />
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <a
                          className={classes.passportButton}
                          target="_blank"
                          href={`${basePath}/api/v7/media/sirton/${user.userId}/${row.userId}`}
                        >
                          <PlayCircleOutlineOutlinedIcon />
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          className={classes.menuButton}
                          ref={anchorRef}
                          aria-controls={open ? "menu-list-grow" : undefined}
                          aria-haspopup="true"
                          onClick={() => handleToggle(row)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        style={{ top: 7, zIndex: 10000 }}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={(e) => adjustElo(activeUserContext, e)}>
                      Set ELO
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => setAsVerified(activeUserContext, e)}
                    >
                      Set player as Verified
                    </MenuItem>
                    <MenuItem
                      onClick={(e) =>
                        setAsRejectedByPassport(activeUserContext, e)
                      }
                    >
                      Reject player's uploaded document
                    </MenuItem>
                    <MenuItem
                      onClick={(e) =>
                        setAsRejectedByVideo(activeUserContext, e)
                      }
                    >
                      Reject player's uploaded video
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => setAsNotEligible(activeUserContext, e)}
                    >
                      Set player as Not Eligible
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => setAsBanned(activeUserContext, e)}
                    >
                      Temporary Ban Player
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </div>
  );
}
