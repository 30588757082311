import * as types from "../types/actionTypes";
import objectAssign from "object-assign";
import fetch from "node-fetch";
import utils from "./../../universal/utils";
import * as cookieStorage from "./common/cookieStorage";

const cStorage = cookieStorage.getCookieStorage();
const GOOGLE_TOKEN_LS_KEY = "ouroGoogleToken";

const basePath = utils.getEdgeBasePath();

export function setUser({ user }) {
  return {
    type: types.LOAD_USER_PROFILE,
    user,
  };
}

export function updateAccountFormField({ name, value }) {
  return {
    type: types.ACCOUNT_FORM_FIELD_CHANGE,
    name,
    value,
  };
}

export function dismissSuccessAccountAlert() {
  return {
    type: types.ACCOUNT_SUCCESS_ALERT_SNOOZE,
  };
}

export function setAccountUpdateAlert(text) {
  return {
    type: types.ACCOUNT_UPDATE_ALERT,
    text,
  };
}

export function dismissAccountUpdateAlert() {
  return {
    type: types.ACCOUNT_UPDATE_ALERT_SNOOZE,
  };
}

export function logout() {
  return {
    type: types.PERFORM_LOGOUT,
  };
}

export function collectMetric(metadata) {
  const stats = cStorage.getObject("stats");
  const { visitorId } = stats;

  return function (dispatch) {
    fetch(`${basePath}/collector/collect/${visitorId}`, {
      method: "POST",
      body: JSON.stringify(metadata),
      headers: { "Content-Type": "application/json" },
    }).then(function (apiResult) {
      return apiResult.json().then(function (json) {
        if (json.ok) {
          console.log("metric reported correctly");
        }
      });
    });
  };
}

export function getTrackingVisitorId() {
  const statsFromCookieStorage = cStorage.getObject("stats");

  if (statsFromCookieStorage !== null) {
    // User has an active visitorId, don't ask for one.
    return function (dispatch) {
      dispatch({
        type: types.STATS_UPDATED,
        stats: statsFromCookieStorage,
      });
    };
  }

  return function (dispatch) {
    fetch(`${basePath}/collector/visitor/id`).then(function (apiResult) {
      return apiResult.json().then(function (result) {
        const stats = {
          data: {
            likes: {
              products: [],
              shops: [],
            },
          },
          visitorId: result.visitorId,
        };

        dispatch({
          type: types.STATS_UPDATED,
          stats,
        });

        cStorage.setObject("stats", stats);
      });
    });
  };
}

export function updateUserELO({ elo, userId, user }) {
  return function (dispatch) {
    fetch(`${basePath}/api/v7/admin/user/update/adpass/${user.userId}`, {
      method: "POST",
      body: JSON.stringify({ elo, userId }),
      headers: { "Content-Type": "application/json" },
    }).then(function (apiResult) {
      return apiResult.json().then(function (result) {
        dispatch({
          type: types.ADMIN_SET_ELO_SUCCESS,
          result,
        });

        getAllUsers({ userId: user.userId })(dispatch);
      });
    });
  };
}

export function updateSetUserAsRejected({ userId, user, material }) {
  return function (dispatch) {
    fetch(`${basePath}/api/v7/admin/user/setasrejected/adpass/${user.userId}`, {
      method: "POST",
      body: JSON.stringify({ userId, material }),
      headers: { "Content-Type": "application/json" },
    }).then(function (apiResult) {
      return apiResult.json().then(function (result) {
        dispatch({
          type: types.ADMIN_SET_PLAYER_AS_REJECTED_SUCCESS,
          result,
        });

        getAllUsers({ userId: user.userId })(dispatch);
      });
    });
  };
}

export function updateSetUserAsVerified({ userId, user }) {
  return function (dispatch) {
    fetch(`${basePath}/api/v7/admin/user/setasverified/adpass/${user.userId}`, {
      method: "POST",
      body: JSON.stringify({ userId }),
      headers: { "Content-Type": "application/json" },
    }).then(function (apiResult) {
      return apiResult.json().then(function (result) {
        dispatch({
          type: types.ADMIN_SET_PLAYER_AS_VERIFIED_SUCCESS,
          result,
        });

        getAllUsers({ userId: user.userId })(dispatch);
      });
    });
  };
}

export function updateUserAsBanned({ days, reason, userId, user }) {
  return function (dispatch) {
    fetch(
      `${basePath}/api/v7/admin/user/setasbanned/adpass/${user.userId}`,
      {
        method: "POST",
        body: JSON.stringify({ userId, days, reason }),
        headers: { "Content-Type": "application/json" },
      }
    ).then(function (apiResult) {
      return apiResult.json().then(function (result) {
        dispatch({
          type: types.ADMIN_SET_PLAYER_BANNED_SUCCESS,
          result,
        });

        getAllUsers({ userId: user.userId })(dispatch);
      });
    });
  };
}

export function updateSetUserAsNotEligible({ userId, user }) {
  return function (dispatch) {
    fetch(
      `${basePath}/api/v7/admin/user/setasnoteligible/adpass/${user.userId}`,
      {
        method: "POST",
        body: JSON.stringify({ userId }),
        headers: { "Content-Type": "application/json" },
      }
    ).then(function (apiResult) {
      return apiResult.json().then(function (result) {
        dispatch({
          type: types.ADMIN_SET_PLAYER_AS_NOT_ELIGIBLE_SUCCESS,
          result,
        });

        getAllUsers({ userId: user.userId })(dispatch);
      });
    });
  };
}

export function setPlayerBannedDialogState(aBool) {
  return {
    type: types.ADMIN_SET_PLAYER_BANNED_DIALOG_STATE,
    state: aBool, // true = open / false = close
  };
}

export function setPlayerRejectedDialogState(aBool) {
  return {
    type: types.ADMIN_SET_PLAYER_REJECTED_DIALOG_STATE,
    state: aBool, // true = open / false = close
  };
}

export function setPlayerNotEligibleDialogState(aBool) {
  return {
    type: types.ADMIN_SET_PLAYER_NOT_ELIGIBLE_DIALOG_STATE,
    state: aBool, // true = open / false = close
  };
}

export function setPlayerVerifiedDialogState(aBool) {
  return {
    type: types.ADMIN_SET_PLAYER_VERIFIED_DIALOG_STATE,
    state: aBool, // true = open / false = close
  };
}

export function setELODialogState(aBool) {
  return {
    type: types.ADMIN_SET_ELO_DIALOG_STATE,
    state: aBool, // true = open / false = close
  };
}

export function setAdminTargetUserContext({ userId, row, material }) {
  return {
    type: types.ADMIN_SET_TARGET_USER_CONTEXT,
    userId,
    row,
    ...(material !== undefined ? { material } : {}),
  };
}

export function getAllUsers({ userId }) {
  if (userId) {
    return function (dispatch) {
      fetch(`${basePath}/api/v7/admin/users/adpass/${userId}`).then(function (
        apiResult
      ) {
        return apiResult.json().then(function (result) {
          dispatch({
            type: types.ADMIN_LOAD_USERS,
            users: result.users,
          });
        });
      });
    };
  }
}

export function submitAccountChanges(data) {
  const credential = localStorage && localStorage.getItem(GOOGLE_TOKEN_LS_KEY);
  if (!credential) {
    return;
  }

  return function (dispatch) {
    dispatch({
      type: types.ACCOUNT_UPDATE_DURING,
    });

    fetch(`${basePath}/api/v1/account`, {
      body: JSON.stringify({
        token: credential,
        data,
      }),
      cache: "no-cache",
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
      mode: "cors",
      redirect: "follow",
      referrer: "no-referrer",
    }).then(function (apiResult) {
      return apiResult.json().then(function (response) {
        return dispatch({
          type: types.ACCOUNT_UPDATE_COMPLETE,
          response,
        });
      });
    });
  };
}
