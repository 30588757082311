import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function SetPlayerAsVerifiedDialog({
  openState,
  userContext,
  onClose,
  onSetPlayerVerified,
}) {
  if ("row" in userContext) {
    const [btnDisabled, setBtnDisabled] = useState(false);

    return (
      <div>
        {"row" in userContext && (
          <Dialog
            open={openState}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Set {userContext.row.nickname} as Verified
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                By clicking save changes you will approve the player 
                <strong>{userContext.row.nickname}</strong> so that he can queue
                for matches
                <br />
                This means you verified his FaceIT , Steam and all other details
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Dismiss
              </Button>
              <Button
                disabled={btnDisabled}
                onClick={() => {
                  setBtnDisabled(true);
                  onSetPlayerVerified(userContext.row.userId);
                  setTimeout(() => {
                    setBtnDisabled(false);
                  }, 5 * 1000);
                }}
                color="primary"
              >
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }

  return <></>;
}
